import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { Link } from "gatsby";

import * as styles from "./styles.module.scss";

const videosQuery = graphql`
  query {
    contentfulVideoList(contentful_id: { eq: "4RfNSn40COsrDZeO6Tr5hX" }) {
      videos {
        id
        title
        youTubeID
      }
    }
  }
`;

const VideoBlock = ({ limit, seeMoreUrl, seeMoreText }) => {
  const {
    contentfulVideoList: { videos },
  } = useStaticQuery(videosQuery);

  return (
    <article className={styles.videoArticle}>
      {videos.slice(0, limit ? limit : videos.length).map((video) => (
        <iframe
          className={styles.videoFrame}
          key={video.youTubeID}
          height="1080"
          width="1920"
          src={`https://www.youtube.com/embed/${video.youTubeID}?modestbranding=1&rel=0&vq=hd1080`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ))}
      {seeMoreUrl && seeMoreText && (
        <div className={styles.readMore}>
          <span className={styles.linkWrapper}>
            <Link to={seeMoreUrl}>{seeMoreText}</Link>
          </span>
        </div>
      )}
    </article>
  );
};

export default VideoBlock;
